*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

*::selection {
    background-color: transparent;
}

html {
    font-size: 20px;
    color: white;
    /* cursor: none; */
}

body {
    background-color: #ebe6c5;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    z-index: 100;
    font-size: 10px;
    box-sizing: border-box;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    width: 100vw;
    height: 100vh;
}

body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}


@media (orientation:portrait) {
    html {
        font-size: 1.25vw;
    }
}
@media (orientation:landscape) {
    html {
        font-size: 1.25vh;
    }
}

.webgl
{
    position: fixed;
    outline: none;
    box-sizing: border-box;
    top: 0;
    z-index: 1000;
    pointer-events: none;
}

/* Slider */
.slider {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
}

.blankSlider {
    position: absolute;
    z-index: 5000;
    width: 100vw;
    height: 800vh;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background-color: transparent;
    pointer-events: none;
}

.sections {
    width: 100vw;
    height: 800vh;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.section {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftSection {
    width: 60vw;
    height: 100vh;
    padding: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rightSection {
    width: 40vw;
    height: 100vh;
    padding: 2vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
}

.mainText {
    width: 100%;
    height: 25vh;
}

.commonName {
    color: #704012;
}

.scientificName {
    color: black;
}

.Vollkorn {
    font-family: 'Vollkorn', serif;
}

.Squada {
    font-family: 'Squada One', cursive;
}

.largeText {
    font-size: 12.5rem;
}

.mediumText {
    font-size: 3.5rem;
}

.pictureClickDiv {
    position: relative;
    z-index: 6000;
    top: calc(50vh - calc(9*2.9vw/2) - 2.25vw);
    right: calc(calc(16*2.9vw/2) + 1.75vw);
    width: calc(16*2.9vw);
    height: calc(9*2.9vw);
    cursor: pointer;
}

.additionalText {
    width: 100%;
    height: 80vh;
    color: black;
    font-size: 3rem;
    font-family: 'Noto Sans', sans-serif;
}

.locationText {
    position: absolute;
    width: 26vw;
    height: 15vh;
    padding-top: 5vh;
    color: black;
    font-size: 2rem;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: 'Permanent Marker', cursive;
}

.location {
    width: 67.5%;
    height: 100%;
}

.date {
    width: 32.5%;
    height: 100%;
    text-align: center;
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

/* NavBar */

.navBar {
    position: fixed;
    top: 0;
    left: 1vw;
    width: calc(100vw - 2vw);
    height: 5vh;
    z-index: 4000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    backdrop-filter: blur(3px);
    /* box-shadow: -2px 2px 5px black; */
    padding: 5px;
    border-radius: 5px;
    pointer-events: auto;
}

.logo {
    font-size: 3rem;
    color: black;
}

.sitePhases {
    height: 100%;
    width: 50vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 3rem;
    color: black
}

.sitePhase {
    height: 100%;
    padding-left: 1vw;
    padding-right: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}

/* .currentPhase {
    border-top: 5px solid black;
    border-bottom: 5px solid black;
} */

.sitePhaseBar {
    height: 1vh;
    width: 20vw;
    /* border: 1px solid black; */
    border-radius: 2.5px;
    /* overflow: hidden; */
}

.loadingBar {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transform-origin: left;
}

/* .loadedBar {
    background-color: black
} */

/* Cursor Follower */
.cursorFollower {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    border-radius: 50%;
    border: 2px solid #6b8c21;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6a8c2150;
}

.eyeIcon, .exitIcon, .enterIcon {
    color: #6b8c21;
    transform: scale(2.5);
    opacity: 0;
    position: absolute;
    text-shadow: 0 0 1px black;
}

/* Scroll Bar */
/* width */
::-webkit-scrollbar {
width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
background: #6b8c21;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #345028;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #345028;
}

.greetingsDiv {
    position: relative;
    width: 100vw;
    height: 100vh;
    /* z-index: 2000; */
}

.goodbyeDiv {
    position: absolute;
    top: 900vh;
    width: 100vw;
    height: 200vh;
}

/* Greetings */
.viewFinder {
    position: absolute;
    width: calc(2*16vw);
    height: calc(2*9vw);
    top: 9vh;
    left: 3vw;
    transform: scale(0.5);
    backdrop-filter: blur(3px);
    background-color: #ffffff0e;
}

.viewFinderCoverText {
    position: absolute;
    width: calc(2*16vw);
    height: calc(2*9vw);
    top: 9vh;
    left: 3vw;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7rem;
    pointer-events: none;
}

.viewFinderActualText {
    position: absolute;
    width: calc(2*16vw);
    height: calc(2*9vw);
    top: 9vh;
    left: 3vw;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    pointer-events: none;
    opacity: 0;
    text-align: center;
    font-family: 'Permanent Marker', cursive;
}

.quote {
    width: 100%;
    height: 33.33%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-size: 19rem;
    color: #704012;
    pointer-events: none;
}

.dash {
    height: 0.75vw;
    width: 28vw;
    background-color: #704012;
    border-radius: 0.2rem;
    margin-top: 5.3vh;
    margin-right: 4vw;
    transform-origin: left;
    transform: scaleX(0);
}

.midDash {
    margin-right: 0;
    width: 42vw;
    transform-origin: left;
    transform: scaleX(0);
}

.nature {
    opacity: 0;
}

.topQuote {
    justify-content: right;
    padding-right: 1vw;
}

.midQuote {
    justify-content: space-between;
    padding-left: 1vw;
}

.botQuote {
    justify-content: left;
}

.TLH {
    position: absolute;
    top: 0;
    left: 0;
    width: 3vw;
    height: 5px;
    background-color: black;
}

.TRH {
    position: absolute;
    top: 0;
    right: 0;
    width: 3vw;
    height: 5px;
    background-color: black;
}

.BLH {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3vw;
    height: 5px;
    background-color: black;
}

.BRH {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 3vw;
    height: 5px;
    background-color: black;
}

.TLV {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 3vw;
    background-color: black;
}

.TRV {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 3vw;
    background-color: black;
}

.BLV {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 5px;
    height: 3vw;
    background-color: black;
}

.BRV {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 5px;
    height: 3vw;
    background-color: black;
}

/* Goodbye */
.viewFinder2 {
    position: absolute;
    width: calc(18vw);
    height: calc(32vw);
    top: 109vh;
    right: 3vw;
    transform: scale(0.6);
    backdrop-filter: blur(3px);
    background-color: #ffffff0e;
}

.viewFinderCoverText2 {
    position: absolute;
    width: calc(18vw);
    height: calc(32vw);
    top: 109vh;
    right: 3vw;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7rem;
    pointer-events: none;
}

.viewFinderActualText2 {
    position: absolute;
    width: calc(18vw);
    height: calc(32vw);
    top: 109vh;
    right: 3vw;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 4rem;
    pointer-events: none;
    opacity: 0;
    text-align: center;
    font-family: 'Permanent Marker', cursive;
}

.link {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Loading Page */
.loadingPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    background-color: #ebe6c5;
    color: black;
    z-index: 100000000000000000000000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20vh;
}

.mainLoadingDiv {
    width: 100vw;
    /* border: 2px solid yellow; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.halfLoadingBar {
    position: absolute;
    width: 100vw;
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.loadingLetter {
    width: calc(100vw/7);
    /* border: 2px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-family: 'Permanent Marker', cursive;
}

#allDoneText {
    position: relative;
    left: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #6b8c21;;
    color: #ebe6c5;
}

#allDone {
    position: absolute;
    z-index: 1000000000000000000000;
    /* border: 2px solid red; */
    background-color: white;
    right: 100vw;
}

.loadPercent {
    position: absolute;
    bottom: 0;
    font-family: 'Permanent Marker', cursive;
    color: black;
    font-size: 0.5rem;
    margin-bottom: 20vh;
}

.loadingNotes {
    position: absolute;
    top: 0;
    color: black;
    font-size: 0.5rem;
    margin-top: 2.5vh;
    font-family: 'Merriweather', serif;
}